




























































import { Prop, Component, Vue } from 'vue-property-decorator'
import { HomepageBanner } from '@/domain/entities/HomepageBanner'
import EditIconNoSquare from '@/app/ui/assets/edit_icon_nosquare.vue'
import dayjs from 'dayjs'
import dayjsID from 'dayjs/locale/id'

@Component({
  components: {
    EditIconNoSquare,
  },
})
export default class BannerItem extends Vue {
  @Prop({ required: true }) private data!: HomepageBanner

  private dateFormat(date: string | null): string {
    if (!date) return '-'

    return dayjs(date)
      .locale(dayjsID)
      .format('DD MMMM YYYY')
  }

  private timeFormat(date: string | null): string {
    if (!date) return '-'

    return dayjs(date)
      .locale(dayjsID)
      .format('HH:mm [WIB]')
  }

  private cancel() {
    this.$emit('cancel', this.data.id)
  }

  get isEditable(): boolean {
    if (!this.data.status) {
      return false
    }

    return ['Active', 'Scheduled'].includes(this.data.status)
  }
}
