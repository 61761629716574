































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/HomepageBannerController'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import BannerItem from './components/BannerItem/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import { HOMEPAGE_BANNER_PAGINATION } from '@/app/infrastructures/misc/Constants/pagination'
import { HomepageBanner } from '@/domain/entities/HomepageBanner'

interface Parameters {
  page: number
  perPage: number
  query: string | undefined
}

@Component({
  components: {
    Button,
    TextInput,
    PaginationNav,
    LoadingOverlay,
    Modal,
    BannerItem,
    EmptyState,
  },
})
export default class HomepageBannerPage extends Vue {
  isGuest = Utils.isGuest()
  controller = controller
  parameters: Parameters = {
    page: 1,
    perPage: HOMEPAGE_BANNER_PAGINATION,
    query: undefined,
  }

  cancelConfirmation = false
  homepageBannerId: number | null = null
  successCancelModal = false

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage:
          Utils.alwaysNumber(queries.perPage) || HOMEPAGE_BANNER_PAGINATION,
        query: queries.query || undefined,
      }
    }

    this.fetchHomepageBannerList()
    controller.countActiveBanner()

    EventBus.$on(EventBusConstants.CANCEL_HOMEPAGE_BANNER_SUCCESS, () => {
      this.successCancelModal = true
      this.cancelConfirmation = false
      this.fetchHomepageBannerList()
    })
  }

  get params(): Record<string, string | number | undefined> {
    return {
      ...this.parameters,
    }
  }

  get bannerItems(): HomepageBanner[] {
    return controller.homepageBannerData
  }

  get isLoading(): boolean {
    return controller.isLoading
  }

  get totalActiveBanner(): number {
    return controller.totalActiveBanner
  }

  @Watch('params')
  onParamsChanged(val: Record<string, never>): void {
    this.$router.replace({
      path: this.$route.path,
      query: { ...val },
    })
  }

  @Watch('parameters.query')
  onKeywordChanged(val: string): void {
    if (val === '') {
      this.parameters.query = undefined
      this.fetchHomepageBannerList(true)
    }
  }

  private fetchHomepageBannerList(resetPage = false): void {
    if (resetPage) {
      this.parameters.page = 1
    }
    this.controller.getHomepageBannerList(this.params)
  }

  private showModalCancel(id: number) {
    this.cancelConfirmation = true
    this.homepageBannerId = id
  }

  private hideConfirmationModal() {
    this.cancelConfirmation = false
    this.homepageBannerId = null
  }

  private cancelHomepageBanner() {
    if (this.homepageBannerId) {
      this.controller.cancelHomepageBanner(this.homepageBannerId)
    }
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.CANCEL_HOMEPAGE_BANNER_SUCCESS)
  }
}
